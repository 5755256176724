import { Badge, Button, Container, Flex, Group, Image, Text } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { IconEdit, IconTrash } from '@tabler/icons-react';

import classes from './CollectionListItem.module.css';
import useDeleteCollection from '../hooks/useDeleteCollection.mjs';


export default function CollectionListItem(props) {
  const { collection } = props;
  const navigate = useNavigate();
  const handleDeleteCollectionBtn = useDeleteCollection(collection.id);

  const collectionPath = `/collection/${collection.id}`;
  const collectionEditPath = `${collectionPath}/edit`;
  // todo: fetch related data (number of items, image previews) (will be added to [GET] /collections)
  // todo: format collection name
  // todo: format collection status

  return (
    <Flex p="md" gap="md" className={classes.listItem} {...props}>
      <Container w="20%" miw="110">
        <Link to={collectionPath}>
          <Image h={110} w={110} src="/110x110.svg" />
        </Link>
        <Flex gap="xs" mt={10}>
          <Image h={30} w={30} src="/30x30.svg" />
          <Image h={30} w={30} src="/30x30.svg" />
          <Image h={30} w={30} src="/30x30.svg" />
        </Flex>
      </Container>
      <Flex align="flex-end" w="80%" direction="column" gap={30}>
        <Link className="g-link" to={collectionPath}>
          <Text size="xl" fw={800}>{collection.name}</Text>
        </Link>
        <Group gap="xs">
          <Text size="sm" fw={800}>Status: </Text>
          <Badge size="md">{collection.status}</Badge>
        </Group>
        <Button.Group>
          <Button onClick={handleDeleteCollectionBtn} size='xs' variant="light" leftSection={<IconTrash size={14} />}>Delete</Button>
          <Button onClick={() => navigate(collectionEditPath)} size='xs' variant="gradient" leftSection={<IconEdit size={14} />}>Edit</Button>
        </Button.Group>
      </Flex>
    </Flex>
  );
}
