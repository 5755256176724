import { Container, Title } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { SITE_TITLE } from '../constants.mjs';
import CollectionArts from '../components/CollectionArts';
import CollectionHeader from '../components/CollectionHeader';
import CollectionJobs from '../components/CollectionJobs';


export default function Collection() {
  const { arts, collection, jobs } = useLoaderData();

  return (
    <Container style={{ padding: '20px 0' }}>
      <Helmet>
        <title>"{collection.name}" | {SITE_TITLE}</title>
      </Helmet>
      <CollectionHeader collection={collection} mb="2rem">
        <Title order={2}>{collection.name}</Title>
      </CollectionHeader>
      <Container px="2rem">
        <CollectionJobs jobs={jobs} />
        <CollectionArts arts={arts} />
      </Container>
    </Container>
  );
}
