import type { AxiosInstance, AxiosRequestConfig } from "axios";
import { useQuery, useMutation, useQueryClient, type QueryClient, type UseMutationOptions, type UseQueryOptions, type MutationFunction, type UseMutationResult, type UseQueryResult } from "@tanstack/react-query";
export type AxiosConfig = {
    paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
};
export type Config = {
    mutations?: MutationConfigs;
    axios?: AxiosConfig;
};
export function initialize(axios: AxiosInstance, config?: Config) {
    const requests = makeRequests(axios, config?.axios);
    return {
        requests,
        queries: makeQueries(requests),
        mutations: makeMutations(requests, config?.mutations)
    };
}
function useRapiniMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(mutationFn: MutationFunction<TData, TVariables>, config?: (queryClient: QueryClient) => Pick<UseMutationOptions<TData, TError, TVariables, TContext>, "onSuccess" | "onSettled" | "onError">, options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn">): UseMutationResult<TData, TError, TVariables, TContext> {
    const { onSuccess, onError, onSettled, ...rest } = options ?? {};
    const queryClient = useQueryClient();
    const conf = config?.(queryClient);
    const mutationOptions: typeof options = {
        onSuccess: (data: TData, variables: TVariables, context?: TContext) => {
            conf?.onSuccess?.(data, variables, context);
            onSuccess?.(data, variables, context);
        },
        onError: (error: TError, variables: TVariables, context?: TContext) => {
            conf?.onError?.(error, variables, context);
            onError?.(error, variables, context);
        },
        onSettled: (data: TData | undefined, error: TError | null, variables: TVariables, context?: TContext) => {
            conf?.onSettled?.(data, error, variables, context);
            onSettled?.(data, error, variables, context);
        },
        ...rest
    };
    return useMutation({ mutationFn, ...mutationOptions });
}
function nullIfUndefined<T>(value: T): NonNullable<T> | null {
    return typeof value === "undefined" ? null : value as NonNullable<T> | null;
}
export const queryKeys = {
    listCollections: () => ["listCollections"] as const,
    getCollection: (id: number) => ["getCollection", id] as const,
    getCollectionWithTemplates: (id: number) => ["getCollectionWithTemplates", id] as const,
    getCollectionArts: (id: number) => ["getCollectionArts", id] as const,
    getCollectionJobs: (id: number) => ["getCollectionJobs", id] as const,
    getCollectionTemplate: (id: number, templateId: number) => ["getCollectionTemplate", id, templateId] as const,
    listTemplates: () => ["listTemplates"] as const,
    getTemplate: (id: number) => ["getTemplate", id] as const,
    getTemplateLayers: (id: number) => ["getTemplateLayers", id] as const,
    listLayers: () => ["listLayers"] as const,
    getLayer: (id: number) => ["getLayer", id] as const,
    getLayerWithTraits: (id: number) => ["getLayerWithTraits", id] as const,
    getTrait: (id: number) => ["getTrait", id] as const
} as const;
export type QueryKeys = typeof queryKeys;
function makeRequests(axios: AxiosInstance, config?: AxiosConfig) {
    return {
        checkHealth: () => axios.request<unknown>({
            method: "head",
            url: `/health`
        }).then(res => res.data),
        listCollections: () => axios.request<{
            name?: string;
        }[]>({
            method: "get",
            url: `/collection`
        }).then(res => res.data),
        createCollection: (payload: {
            name?: string;
        }) => axios.request<{
            success?: boolean;
            id?: number | null;
        }>({
            method: "post",
            url: `/collection`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        getCollection: (id: number) => axios.request<{
            name?: string;
        }>({
            method: "get",
            url: `/collection/${id}`
        }).then(res => res.data),
        deleteCollection: (id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "delete",
            url: `/collection/${id}`
        }).then(res => res.data),
        updateCollection: (payload: {
            name?: string;
        }, id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "put",
            url: `/collection/${id}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        getCollectionWithTemplates: (id: number) => axios.request<{
            name?: string;
            status?: "draft" | "generated" | "minted";
            templates?: {
                template_id?: number;
            }[];
        }>({
            method: "get",
            url: `/collection/${id}/templates`
        }).then(res => res.data),
        getCollectionArts: (id: number) => axios.request<{
            id?: number;
            url?: string;
            batch_id?: string;
            metadata?: string;
        }[]>({
            method: "get",
            url: `/collection/${id}/arts`
        }).then(res => res.data),
        getCollectionJobs: (id: number) => axios.request<{
            id?: number;
            collectionSize?: number;
            failed?: boolean;
            finished?: boolean;
            progress?: number;
            timestamp?: number;
        }[]>({
            method: "get",
            url: `/collection/${id}/jobs`
        }).then(res => res.data),
        generateCollection: (payload: {
            artWidth?: number;
            artHeight?: number;
            collectionSize?: number;
            templateSelection?: string;
        }, id: number) => axios.request<{
            success?: boolean;
            jobIDs?: number[];
        }>({
            method: "post",
            url: `/collection/${id}/generate`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        deleteJob: (id: number) => axios.request<{
            success?: boolean;
            message?: string;
        }>({
            method: "delete",
            url: `/job/${id}`
        }).then(res => res.data),
        getCollectionTemplate: (id: number, templateId: number) => axios.request<{
            id?: number;
            template_id?: number;
            name?: string;
            hidden?: boolean;
            traits?: {
                id?: number;
                name?: string;
                rarity?: number;
                asset_id?: number;
                asset_url?: string;
            }[];
        }>({
            method: "get",
            url: `/collection/${id}/template/${templateId}`
        }).then(res => res.data),
        listTemplates: () => axios.request<{
            collection_id?: number;
            layer_order?: string;
        }[]>({
            method: "get",
            url: `/template`
        }).then(res => res.data),
        createTemplate: (payload: {
            collection_id?: number;
        }) => axios.request<{
            success?: boolean;
            id?: number | null;
        }>({
            method: "post",
            url: `/template`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        getTemplate: (id: number) => axios.request<{
            collection_id?: number;
            layer_order?: string;
        }>({
            method: "get",
            url: `/template/${id}`
        }).then(res => res.data),
        deleteTemplate: (id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "delete",
            url: `/template/${id}`
        }).then(res => res.data),
        updateTemplate: (payload: {
            collection_id?: number;
            layer_order?: string;
        }, id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "put",
            url: `/template/${id}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        getTemplateLayers: (id: number) => axios.request<{
            id?: number;
            template_id?: number;
            name?: string;
            hidden?: boolean;
            traits?: {
                id?: number;
                name?: string;
                rarity?: number;
                asset_id?: number;
                asset_url?: string;
            }[];
        }>({
            method: "get",
            url: `/template/${id}/layers`
        }).then(res => res.data),
        duplicateTemplate: (payload: {
            collection_id?: number;
        }, id: number) => axios.request<{
            success?: boolean;
            id?: number | null;
            template?: {
                id?: number;
                collection_id?: number;
                layer_order?: string;
            };
        }>({
            method: "post",
            url: `/template/${id}/duplicate`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        listLayers: () => axios.request<{
            template_id?: number;
            name?: string;
            hidden?: boolean;
        }[]>({
            method: "get",
            url: `/layer`
        }).then(res => res.data),
        createLayer: (payload: {
            template_id?: number;
            name?: string;
            hidden?: boolean;
        }) => axios.request<{
            success?: boolean;
            id?: number | null;
        }>({
            method: "post",
            url: `/layer`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        getLayer: (id: number) => axios.request<{
            template_id?: number;
            name?: string;
            hidden?: boolean;
        }>({
            method: "get",
            url: `/layer/${id}`
        }).then(res => res.data),
        deleteLayer: (id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "delete",
            url: `/layer/${id}`
        }).then(res => res.data),
        updateLayer: (payload: {
            template_id?: number;
            name?: string;
            hidden?: boolean;
        }, id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "put",
            url: `/layer/${id}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        getLayerWithTraits: (id: number) => axios.request<{
            id?: number;
            template_id?: number;
            name?: string;
            hidden?: boolean;
            traits?: {
                id?: number;
                name?: string;
                rarity?: number;
                asset_id?: number;
                asset_url?: string;
            }[];
        }>({
            method: "get",
            url: `/layer/${id}/traits`
        }).then(res => res.data),
        duplicateLayer: (id: number) => axios.request<{
            success?: boolean;
            duplicatedLayerId?: number | null;
        }>({
            method: "post",
            url: `/layer/${id}/duplicate`
        }).then(res => res.data),
        createTrait: (payload: {
            layer_id?: number;
            file?: any[];
        }) => axios.request<{
            success?: boolean;
            id?: number | null;
            trait?: {
                id?: number;
                name?: string;
                rarity?: number;
                asset_id?: number;
                asset_url?: string;
            };
        }>({
            method: "post",
            url: `/trait`,
            data: payload,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => res.data),
        getTrait: (id: number) => axios.request<{
            layer_id?: number;
            name?: string;
            rarity?: number;
            asset_id?: number;
        }>({
            method: "get",
            url: `/trait/${id}`
        }).then(res => res.data),
        deleteTrait: (id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "delete",
            url: `/trait/${id}`
        }).then(res => res.data),
        updateTrait: (payload: {
            layer_id?: number;
            name?: string;
            rarity?: number;
            asset_id?: number;
        }, id: number) => axios.request<{
            success?: boolean;
        }>({
            method: "put",
            url: `/trait/${id}`,
            data: payload,
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data),
        updateTraitWithUpload: (payload: {
            file?: any[];
        }, id: number) => axios.request<{
            success?: boolean;
            newAssetUrl?: string;
        }>({
            method: "put",
            url: `/trait/${id}/upload`,
            data: payload,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => res.data)
    } as const;
}
export type Requests = ReturnType<typeof makeRequests>;
export type Response<T extends keyof Requests> = Awaited<ReturnType<Requests[T]>>;
function makeQueries(requests: Requests) {
    return {
        useListCollections: (options?: Omit<UseQueryOptions<Response<"listCollections">, unknown, Response<"listCollections">, ReturnType<QueryKeys["listCollections"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"listCollections">, unknown> => useQuery({ queryKey: queryKeys.listCollections(), queryFn: () => requests.listCollections(), ...options }),
        useGetCollection: (id: number, options?: Omit<UseQueryOptions<Response<"getCollection">, unknown, Response<"getCollection">, ReturnType<QueryKeys["getCollection"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getCollection">, unknown> => useQuery({ queryKey: queryKeys.getCollection(id), queryFn: () => requests.getCollection(id), ...options }),
        useGetCollectionWithTemplates: (id: number, options?: Omit<UseQueryOptions<Response<"getCollectionWithTemplates">, unknown, Response<"getCollectionWithTemplates">, ReturnType<QueryKeys["getCollectionWithTemplates"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getCollectionWithTemplates">, unknown> => useQuery({ queryKey: queryKeys.getCollectionWithTemplates(id), queryFn: () => requests.getCollectionWithTemplates(id), ...options }),
        useGetCollectionArts: (id: number, options?: Omit<UseQueryOptions<Response<"getCollectionArts">, unknown, Response<"getCollectionArts">, ReturnType<QueryKeys["getCollectionArts"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getCollectionArts">, unknown> => useQuery({ queryKey: queryKeys.getCollectionArts(id), queryFn: () => requests.getCollectionArts(id), ...options }),
        useGetCollectionJobs: (id: number, options?: Omit<UseQueryOptions<Response<"getCollectionJobs">, unknown, Response<"getCollectionJobs">, ReturnType<QueryKeys["getCollectionJobs"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getCollectionJobs">, unknown> => useQuery({ queryKey: queryKeys.getCollectionJobs(id), queryFn: () => requests.getCollectionJobs(id), ...options }),
        useGetCollectionTemplate: (id: number, templateId: number, options?: Omit<UseQueryOptions<Response<"getCollectionTemplate">, unknown, Response<"getCollectionTemplate">, ReturnType<QueryKeys["getCollectionTemplate"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getCollectionTemplate">, unknown> => useQuery({ queryKey: queryKeys.getCollectionTemplate(id, templateId), queryFn: () => requests.getCollectionTemplate(id, templateId), ...options }),
        useListTemplates: (options?: Omit<UseQueryOptions<Response<"listTemplates">, unknown, Response<"listTemplates">, ReturnType<QueryKeys["listTemplates"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"listTemplates">, unknown> => useQuery({ queryKey: queryKeys.listTemplates(), queryFn: () => requests.listTemplates(), ...options }),
        useGetTemplate: (id: number, options?: Omit<UseQueryOptions<Response<"getTemplate">, unknown, Response<"getTemplate">, ReturnType<QueryKeys["getTemplate"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getTemplate">, unknown> => useQuery({ queryKey: queryKeys.getTemplate(id), queryFn: () => requests.getTemplate(id), ...options }),
        useGetTemplateLayers: (id: number, options?: Omit<UseQueryOptions<Response<"getTemplateLayers">, unknown, Response<"getTemplateLayers">, ReturnType<QueryKeys["getTemplateLayers"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getTemplateLayers">, unknown> => useQuery({ queryKey: queryKeys.getTemplateLayers(id), queryFn: () => requests.getTemplateLayers(id), ...options }),
        useListLayers: (options?: Omit<UseQueryOptions<Response<"listLayers">, unknown, Response<"listLayers">, ReturnType<QueryKeys["listLayers"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"listLayers">, unknown> => useQuery({ queryKey: queryKeys.listLayers(), queryFn: () => requests.listLayers(), ...options }),
        useGetLayer: (id: number, options?: Omit<UseQueryOptions<Response<"getLayer">, unknown, Response<"getLayer">, ReturnType<QueryKeys["getLayer"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getLayer">, unknown> => useQuery({ queryKey: queryKeys.getLayer(id), queryFn: () => requests.getLayer(id), ...options }),
        useGetLayerWithTraits: (id: number, options?: Omit<UseQueryOptions<Response<"getLayerWithTraits">, unknown, Response<"getLayerWithTraits">, ReturnType<QueryKeys["getLayerWithTraits"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getLayerWithTraits">, unknown> => useQuery({ queryKey: queryKeys.getLayerWithTraits(id), queryFn: () => requests.getLayerWithTraits(id), ...options }),
        useGetTrait: (id: number, options?: Omit<UseQueryOptions<Response<"getTrait">, unknown, Response<"getTrait">, ReturnType<QueryKeys["getTrait"]>>, "queryKey" | "queryFn">): UseQueryResult<Response<"getTrait">, unknown> => useQuery({ queryKey: queryKeys.getTrait(id), queryFn: () => requests.getTrait(id), ...options })
    } as const;
}
type MutationConfigs = {
    useCreateCollection?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"createCollection">, unknown, Parameters<Requests["createCollection"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUpdateCollection?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"updateCollection">, unknown, Parameters<Requests["updateCollection"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useDeleteCollection?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"deleteCollection">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useGenerateCollection?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"generateCollection">, unknown, Parameters<Requests["generateCollection"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useDeleteJob?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"deleteJob">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useCreateTemplate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"createTemplate">, unknown, Parameters<Requests["createTemplate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUpdateTemplate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"updateTemplate">, unknown, Parameters<Requests["updateTemplate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useDeleteTemplate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"deleteTemplate">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useDuplicateTemplate?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"duplicateTemplate">, unknown, Parameters<Requests["duplicateTemplate"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useCreateLayer?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"createLayer">, unknown, Parameters<Requests["createLayer"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUpdateLayer?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"updateLayer">, unknown, Parameters<Requests["updateLayer"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useDeleteLayer?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"deleteLayer">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useDuplicateLayer?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"duplicateLayer">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useCreateTrait?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"createTrait">, unknown, Parameters<Requests["createTrait"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useUpdateTrait?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"updateTrait">, unknown, Parameters<Requests["updateTrait"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
    useDeleteTrait?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"deleteTrait">, unknown, unknown, unknown>, "onSuccess" | "onSettled" | "onError">;
    useUpdateTraitWithUpload?: (queryClient: QueryClient) => Pick<UseMutationOptions<Response<"updateTraitWithUpload">, unknown, Parameters<Requests["updateTraitWithUpload"]>[0], unknown>, "onSuccess" | "onSettled" | "onError">;
};
function makeMutations(requests: Requests, config?: Config["mutations"]) {
    return {
        useCreateCollection: (options?: Omit<UseMutationOptions<Response<"createCollection">, unknown, Parameters<Requests["createCollection"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"createCollection">, unknown, Parameters<Requests["createCollection"]>[0]>(payload => requests.createCollection(payload), config?.useCreateCollection, options),
        useUpdateCollection: (id: number, options?: Omit<UseMutationOptions<Response<"updateCollection">, unknown, Parameters<Requests["updateCollection"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"updateCollection">, unknown, Parameters<Requests["updateCollection"]>[0]>(payload => requests.updateCollection(payload, id), config?.useUpdateCollection, options),
        useDeleteCollection: (id: number, options?: Omit<UseMutationOptions<Response<"deleteCollection">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"deleteCollection">, unknown, unknown>(() => requests.deleteCollection(id), config?.useDeleteCollection, options),
        useGenerateCollection: (id: number, options?: Omit<UseMutationOptions<Response<"generateCollection">, unknown, Parameters<Requests["generateCollection"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"generateCollection">, unknown, Parameters<Requests["generateCollection"]>[0]>(payload => requests.generateCollection(payload, id), config?.useGenerateCollection, options),
        useDeleteJob: (id: number, options?: Omit<UseMutationOptions<Response<"deleteJob">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"deleteJob">, unknown, unknown>(() => requests.deleteJob(id), config?.useDeleteJob, options),
        useCreateTemplate: (options?: Omit<UseMutationOptions<Response<"createTemplate">, unknown, Parameters<Requests["createTemplate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"createTemplate">, unknown, Parameters<Requests["createTemplate"]>[0]>(payload => requests.createTemplate(payload), config?.useCreateTemplate, options),
        useUpdateTemplate: (id: number, options?: Omit<UseMutationOptions<Response<"updateTemplate">, unknown, Parameters<Requests["updateTemplate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"updateTemplate">, unknown, Parameters<Requests["updateTemplate"]>[0]>(payload => requests.updateTemplate(payload, id), config?.useUpdateTemplate, options),
        useDeleteTemplate: (id: number, options?: Omit<UseMutationOptions<Response<"deleteTemplate">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"deleteTemplate">, unknown, unknown>(() => requests.deleteTemplate(id), config?.useDeleteTemplate, options),
        useDuplicateTemplate: (id: number, options?: Omit<UseMutationOptions<Response<"duplicateTemplate">, unknown, Parameters<Requests["duplicateTemplate"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"duplicateTemplate">, unknown, Parameters<Requests["duplicateTemplate"]>[0]>(payload => requests.duplicateTemplate(payload, id), config?.useDuplicateTemplate, options),
        useCreateLayer: (options?: Omit<UseMutationOptions<Response<"createLayer">, unknown, Parameters<Requests["createLayer"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"createLayer">, unknown, Parameters<Requests["createLayer"]>[0]>(payload => requests.createLayer(payload), config?.useCreateLayer, options),
        useUpdateLayer: (id: number, options?: Omit<UseMutationOptions<Response<"updateLayer">, unknown, Parameters<Requests["updateLayer"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"updateLayer">, unknown, Parameters<Requests["updateLayer"]>[0]>(payload => requests.updateLayer(payload, id), config?.useUpdateLayer, options),
        useDeleteLayer: (id: number, options?: Omit<UseMutationOptions<Response<"deleteLayer">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"deleteLayer">, unknown, unknown>(() => requests.deleteLayer(id), config?.useDeleteLayer, options),
        useDuplicateLayer: (id: number, options?: Omit<UseMutationOptions<Response<"duplicateLayer">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"duplicateLayer">, unknown, unknown>(() => requests.duplicateLayer(id), config?.useDuplicateLayer, options),
        useCreateTrait: (options?: Omit<UseMutationOptions<Response<"createTrait">, unknown, Parameters<Requests["createTrait"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"createTrait">, unknown, Parameters<Requests["createTrait"]>[0]>(payload => requests.createTrait(payload), config?.useCreateTrait, options),
        useUpdateTrait: (id: number, options?: Omit<UseMutationOptions<Response<"updateTrait">, unknown, Parameters<Requests["updateTrait"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"updateTrait">, unknown, Parameters<Requests["updateTrait"]>[0]>(payload => requests.updateTrait(payload, id), config?.useUpdateTrait, options),
        useDeleteTrait: (id: number, options?: Omit<UseMutationOptions<Response<"deleteTrait">, unknown, unknown, unknown>, "mutationFn">) => useRapiniMutation<Response<"deleteTrait">, unknown, unknown>(() => requests.deleteTrait(id), config?.useDeleteTrait, options),
        useUpdateTraitWithUpload: (id: number, options?: Omit<UseMutationOptions<Response<"updateTraitWithUpload">, unknown, Parameters<Requests["updateTraitWithUpload"]>[0], unknown>, "mutationFn">) => useRapiniMutation<Response<"updateTraitWithUpload">, unknown, Parameters<Requests["updateTraitWithUpload"]>[0]>(payload => requests.updateTraitWithUpload(payload, id), config?.useUpdateTraitWithUpload, options)
    } as const;
}
