import { ActionIcon, Badge, Group, Paper, Progress, Stack, Text, Tooltip } from '@mantine/core';
import { IconAlertTriangle, IconInfoCircle, IconSquareX } from '@tabler/icons-react';
import { useRevalidator } from 'react-router-dom';

import classes from './QueueJob.module.css';
import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import { useDialog } from '../providers/DialogProvider';


function getJobStatus(job) {
  if (job.failed) {
    return 'Failed';
  } else if (job.finished) {
    return job.progress === job.collectionSize ? 'Success' : 'Done';
  }
  return 'In Progress';
}

export default function QueueJob({ job }) {
  const [, { close, open, stopLoading }] = useDialog({
    confirmText: "Cancel Job",
    text: "This will stop and delete the job.",
    title: "Irreversible Action",
  });
  const revalidator = useRevalidator();

  const deleteJob = rapini.mutations.useDeleteJob(job.id, {
    onSuccess: ({ message, success }) => {
      if (!success) {
        stopLoading();
        return notify({ message: message, title: 'The job could not be deleted' });
      }
      revalidator.revalidate();
      close();
    },
    onError: () => {
      stopLoading();
    },
  });

  function handleDeleteBtn() {
    deleteJob.mutate();
  }

  const jobStatus = getJobStatus(job);
  const infoText = <div>
    {jobStatus === 'Done' && <p>
      Generation completed successfully,<br/>
      but there are some possible trait<br/>
      combinations did not manifested due<br/>
      to their rarity or having duplicates.
    </p>}
    <div><strong>Job ID:</strong> {job.id}</div>
    <div><strong>Generated:</strong> {job.generated}</div>
    <div><strong>Trait Count:</strong> {job.traitCount}</div>
    <div><strong>Created At:</strong> {(new Date(job.timestamp)).toLocaleString()}</div>
  </div>;

  return (
    <Paper className={classes.job} key={job.id} withBorder>
      <Stack h="100%" justify="space-between">
        <Group gap="xs" justify="space-between">
          <Group gap={4}>
            <Badge size="sm">{jobStatus}</Badge>
            {jobStatus === 'In Progress' &&
              <Tooltip label="Cancel job">
                <ActionIcon
                  aria-label="Cancel job"
                  disabled={deleteJob.isPending}
                  onClick={open(handleDeleteBtn)}
                  size="xs"
                  variant="light"
                >
                  <IconSquareX size={16} />
                </ActionIcon>
              </Tooltip>
            }
          </Group>
          <Tooltip events={{ touch: true }} label={infoText}>
            {jobStatus === 'Done' ?
              <IconAlertTriangle size={16} color="var(--mantine-color-warning)" /> :
              <IconInfoCircle size={16} />
            }
          </Tooltip>
        </Group>
        <Text size="sm" ta="center">
          {job.progress} of {job.collectionSize}<br/>
          arts generated
        </Text>
        <Progress
          animated={jobStatus === 'In Progress'}
          color="var(--mantine-color-cyan-5)"
          size="lg"
          value={job.progress * (100 / job.collectionSize)}
        />
      </Stack>
    </Paper>
  );
}
