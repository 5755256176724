import { useSyncExternalStore } from 'react';

import { GENERAL_CONFIG, TEMPLATE_CONFIG } from '../constants.mjs';
import { getTemplateConfigKey } from '../helpers';
import ConfigStore from '../helpers/ConfigStore.mjs';


const configDefaults = {
  [GENERAL_CONFIG]: {
    artPreview: true,
    layersOverview: false,
  },
  [TEMPLATE_CONFIG]: {
    collectionSize: 0,
    maxCollectionSize: 0,
    artWidth: 400,
    artHeight: 400,
  }
};

function useConfigStore(configKey, selector, configDefaults) {
  const configStore = new ConfigStore(configKey, configDefaults);
  const config = useSyncExternalStore(configStore.subscribe, configStore.getSnapshotFn(selector));
  return [JSON.parse(config), configStore.set];
}

export function useGeneralConfig(selector) {
  return useConfigStore(GENERAL_CONFIG, selector, configDefaults[GENERAL_CONFIG]);
}

export function useTemplateConfig(templateId, selector) {
  return useConfigStore(getTemplateConfigKey(templateId), selector, configDefaults[TEMPLATE_CONFIG]);
}
