import { redirect } from 'react-router-dom';

import { rapini } from '../api/client.mjs';


export default async function loader({ params }) {
    let { collectionId } = params;
    const collection = await rapini.requests.getCollectionWithTemplates(collectionId);

    if (collection.status === window.g_.COLLECTION_STATUSES.DRAFT) {
        return redirect(`/collection/${collectionId}/edit`);
    }

    const arts = await rapini.requests.getCollectionArts(collectionId);
    const jobs = await rapini.requests.getCollectionJobs(collectionId);

    return { arts, collection, jobs };
}
