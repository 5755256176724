import { Box, Collapse, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconZzz } from '@tabler/icons-react';

import classes from './CollectionJobs.module.css';
import QueueJob from './QueueJob';
import SectionHeader from './SectionHeader';


export default function CollectionJobs({ jobs }) {
  const [opened, { toggle }] = useDisclosure(jobs.length > 0);

  return (
    <Box mb="2rem">
      <SectionHeader label="Show/Hide jobs" title="Jobs" toggle={toggle}/>
      <Collapse in={opened}>
        <Group className={classes.container} p="1rem">
          {jobs.length > 0 ?
            jobs.map(job =>
              <QueueJob key={job.id} job={job} />
            )
            :
            <Flex
              align="center"
              bd="1px solid #243a59"
              direction="column"
              gap="1rem"
              m="1rem auto"
              p="2rem"
            >
              <Text ta="center">
                There isn't any jobs running at the moment.
                <IconZzz size={14} />
              </Text>
            </Flex>
          }
        </Group>
      </Collapse>
    </Box>
  );
}
