import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import { useDialog } from '../providers/DialogProvider.jsx';


export default function useDeleteCollection(collectionId) {
    const location = useLocation();
    const collectionsPath = '/collections';
    const onCollectionsPage = location.pathname.startsWith(collectionsPath);
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const [, { close, open, stopLoading }] = useDialog({
        confirmText: "Delete Collection",
        text: "The collection and all related items (including ARTS) will be deleted.",
        title: "Irreversible Action",
    });

    const deleteCollection = rapini.mutations.useDeleteCollection(collectionId, {
        onSuccess: ({ success }) => {
            if (!success) {
                return notify({ message: 'The collection could not be deleted.' });
            }
            queryClient
                .invalidateQueries({ queryKey: rapini.queryKeys.listCollections() })
                .then(() => {
                    if (!onCollectionsPage) {
                        navigate(collectionsPath);
                    }
                    close();
                }).catch(err => {
                stopLoading();
                notify({ message: `Collections could not be retrieved. ${err?.message || ''}` });
            });
        },
        onError: () => {
            stopLoading();
        },
    });

    return function handleDeleteCollectionBtn() {
        open(deleteCollection.mutate)();
    }
}
