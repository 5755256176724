import { Box, Button, Collapse, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { IconEdit } from '@tabler/icons-react';


import classes from './CollectionArts.module.css';
import Art from './Art';
import SectionHeader from './SectionHeader';


export default function CollectionArts({ arts }) {
  const [opened, { toggle }] = useDisclosure(arts.length > 0);
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const navPath = `/collection/${collectionId}/edit`;

  return (
    <Box mb="2rem">
      <SectionHeader label="Show/Hide arts" title="Arts" toggle={toggle}/>
      <Collapse in={opened}>
        <Group className={classes.container} p="1rem">
          {arts.length > 0 ?
            arts.map(art =>
              <Art key={art.id} art={art} />
            )
            :
            <Flex
              align="center"
              bd="1px solid #243a59"
              direction="column"
              gap="1rem"
              m="1rem auto"
              p="2rem"
            >
              <Text ta="center">Currently the collection doesn't have any arts.</Text>
              <Button
                aria-label="Edit collection"
                leftSection={<IconEdit size={14} />}
                onClick={() => navigate(navPath)}
                size="sm"
                variant="gradient"
              >
                Generate Some
              </Button>
            </Flex>
          }

        </Group>
      </Collapse>
    </Box>
  );
}
