import { Anchor, Group, Image, Paper, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import classes from './Art.module.css';


export default function Art({ art }) {
  const metadata = <div>
    {Object.entries(JSON.parse(art.metadata))
      .slice(0, -1)
      .map(([key, val]) =>
        <div key={key}><strong>{key}:</strong> {val}</div>
      )
    }
  </div>;

  return (
    <Paper className={classes.art}>
      <Group p="2px 5px" justify="flex-end" gap="xs">
        <Tooltip events={{ touch: true }} label={metadata} withinPortal={true}>
          <IconInfoCircle className={classes.artInfoCircle} size={16} />
        </Tooltip>
      </Group>
      <Tooltip.Floating label={metadata} withinPortal={true}>
        <div className={classes.artImageFrame}>
          <Anchor href={art.url} target="_blank">
            <Image src={art.url} className={classes.artImage}/>
          </Anchor>
        </div>
      </Tooltip.Floating>
    </Paper>
  );
}
