import { Button, Group, Modal, Text, useMantineTheme } from '@mantine/core';


export default function Dialog(props) {
  const theme = useMantineTheme();
  const {
    actionFn,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    disclosure,
    loading = false,
    text = 'Are you sure to proceed?',
    title = ''
  } = props;
  const [opened, { close }] = disclosure;
  const withCloseButton = title !== '';

  return (
    <Modal
      closeButtonProps={{
        disabled: loading,
      }}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      opened={opened} onClose={close} title={title}
      styles={{
        content: { backgroundColor: theme.other.heroDark },
        header: { backgroundColor: theme.colors.dark[9] },
        title: { fontWeight: 'bold' },
      }}
      withCloseButton={withCloseButton}
      zIndex={900}
    >
      <Text py={15} px={10}>{text}</Text>
      <Group mt="md" justify="flex-end">
        <Button loading={loading} variant="light" onClick={close}>{cancelText}</Button>
        <Button loading={loading} color="pink" onClick={actionFn}>{confirmText}</Button>
      </Group>
    </Modal>
  );
}
