import { ActionIcon, Button, Flex, Tooltip } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconStack2,  IconEdit, IconTrash } from '@tabler/icons-react';

import useDeleteCollection from '../hooks/useDeleteCollection.mjs';


export default function CollectionHeader({ children, collection, ...props }) {
  const location = useLocation();
  const editMode = location.pathname.endsWith('/edit');
  const navPath = `/collection/${collection.id}${editMode ? '' : '/edit'}`;
  const navBtnText = editMode ? 'Back to Details' : 'Show Templates';

  const navigate = useNavigate();
  const handleDeleteCollectionBtn = useDeleteCollection(collection.id);

  return (
    <Flex
      align="center"
      className="g-title"
      gap="md"
      direction={{ base: 'column', xs: 'row'}}
      justify={{ base: 'center', xs: 'space-between'}}
      {...props}
    >
      {children}
      <Button.Group>
        <Button
          leftSection={editMode ? <IconStack2 size={14} /> : <IconEdit size={14} />}
          onClick={() => navigate(navPath)}
          size="sm"
          variant="gradient"
        >
          {navBtnText}
        </Button>
        <Tooltip label="Delete collection">
          <ActionIcon
            aria-label="Delete collection"
            onClick={handleDeleteCollectionBtn}
            radius={0}
            size={36}
            variant="light"
          >
            <IconTrash size={14} />
          </ActionIcon>
        </Tooltip>
      </Button.Group>
    </Flex>
  );
}
