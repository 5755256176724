import { ActionIcon, Divider, Group, Title, Tooltip } from '@mantine/core';
import { IconSwitchVertical } from '@tabler/icons-react';


export default function SectionHeader({ label, title, toggle }) {
  return (
    <>
      <Group justify="space-between">
        <Title order={4} ml="1rem" mb={5}>{title}</Title>
        <Tooltip label={label}>
          <ActionIcon
            aria-label={label}
            mb={4}
            mr="1rem"
            onClick={toggle}
            variant="subtle"
          >
            <IconSwitchVertical size={20} />
          </ActionIcon>
        </Tooltip>
      </Group>
      <Divider mb="0.5rem" />
    </>
  );
}
